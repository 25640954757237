@import 'styles/breakpoints';

.toggle-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  padding: var(--spacing-100);
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--radius-sm);
  background-color: var(--bg-layer);

  .toggle-button {
    cursor: pointer;
    font-weight: 600;
    display: flex;
    padding: var(--spacing-300) var(--spacing-500);
    line-height: 1;
    font-size: var(--text-sm-font-size);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-200);
    flex: 1 0 auto;
    align-self: stretch;
    border-radius: var(--radius-sm);
    color: #898cab;
    border: 1px solid transparent;

    @include until($widthMobile) {
      padding: var(--spacing-300);
    }

    &.active,
    &:hover {
      color: var(--text-default);
    }

    &.active {
      border: 1px solid #dfdfdf;
      background-color: var(--white);
      box-shadow: 0 1px 2px 0 rgba(150, 150, 150, 0.12);
    }

    &:hover:not(.active) {
      background-color: #dcdfe8;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.fit-content {
    width: fit-content;

    .toggle-button {
      align-self: flex-start;
    }
  }

  &.small-gray {
    gap: 2px;
    background-color: var(--white);

    button {
      font-size: 11px;
      line-height: 1.4;
      padding: 2px var(--spacing-200);
      background-color: transparent;
      color: rgba(var(--blue-800), 0.5);
      border-radius: var(--radius-sm);
      width: fit-content;

      &.active {
        background-color: #e7e8f0;
        border: none;
        color: var(--text-default);
      }

      &:hover:not(.active) {
        background-color: rgba(#dcdfe8, 0.25);
      }
    }
  }
}
