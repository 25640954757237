@import 'styles/breakpoints';

.bolt-go {
  .hero p,
  .intro p,
  .info-section p {
    line-height: 1.6;
  }

  .intro p {
    margin-top: var(--spacing-200);
  }

  small.tag {
    width: fit-content;
    border-radius: var(--radius-sm);
    padding: var(--spacing-100) var(--spacing-200);
    font-weight: bold;
  }

  .hero-btn {
    width: fit-content;

    @include until($widthMobile) {
      width: 100%;
    }
  }

  .usps {
    border-bottom: 1px solid var(--border-subdued);
    background-color: var(--white);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: min(80px, 7.5vw);
    padding: var(--spacing-500) var(--spacing-800);
    margin: 0 auto;

    @include until($widthTablet) {
      flex-direction: column;
      gap: var(--spacing-500);
      padding: var(--spacing-600) var(--spacing-500);
    }

    .usp {
      display: flex;
      align-items: center;
      gap: var(--spacing-300);
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
      font-weight: 600;
      color: var(--text-default);

      svg {
        color: var(--text-primary);
      }
    }
  }

  .offering-section {
    padding-top: var(--spacing-900);
    padding-bottom: var(--spacing-900);

    @include until($widthTablet) {
      padding-top: var(--spacing-600);
      padding-bottom: var(--spacing-600);
    }

    :global(.container) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: var(--spacing-700);
      row-gap: var(--spacing-600);

      @include until($widthTablet) {
        column-gap: var(--spacing-500);
      }

      @include until($widthMobile) {
        row-gap: var(--spacing-400);
        grid-template-columns: 1fr;
      }
    }

    .intro {
      grid-column: -1 / 1;
      max-width: 820px;

      @include until($widthMobile) {
        margin-bottom: var(--spacing-200);
      }
    }

    .offering {
      background-color: var(--white);
      padding: var(--spacing-700);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-400);

      @include until($widthTablet) {
        padding: var(--spacing-500) var(--spacing-500);
        gap: var(--spacing-300);
      }

      &.dark {
        background-color: var(--bg-layer);
      }

      .advantages {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-400);

        @include until($widthTablet) {
          gap: var(--spacing-300);
        }

        .advantage {
          display: flex;
          align-items: flex-start;
          gap: var(--spacing-300);
          line-height: 24px;

          svg {
            color: var(--text-primary);
            flex-shrink: 0;
          }
        }
      }

      hr {
        margin: var(--spacing-400) 0;
      }

      p {
        opacity: 0.6;
      }

      .estimations {
        width: 100%;
        display: grid;
        gap: var(--spacing-400);
        grid-template-columns: repeat(2, 1fr);

        .estimation {
          display: flex;
          align-items: center;
          gap: var(--spacing-200);

          svg {
            color: var(--accent-purple);
          }

          &:first-child {
            svg {
              color: var(--text-primary);
            }
          }

          data {
            font-size: var(--text-lg-font-size);
          }
        }
      }

      button {
        margin-top: auto;
      }
    }
  }

  .info-section {
    padding-top: calc(var(--spacing-900) + var(--spacing-500));
    padding-bottom: var(--spacing-900);

    @include until($widthTablet) {
      padding: var(--spacing-600) var(--spacing-500);
    }

    .info-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: var(--spacing-600);
      row-gap: var(--spacing-900);

      @include until($widthTablet) {
        grid-template-columns: 1fr;
        row-gap: var(--spacing-600);
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      h4 {
        margin-bottom: var(--spacing-300);
        margin-top: var(--spacing-600);

        &:first-of-type {
          margin-top: var(--spacing-400);
        }
      }

      a {
        color: var(--text-default);
        text-decoration: underline;
      }

      &:last-of-type {
        justify-content: flex-start;

        h4 {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }

    .image-wrapper {
      display: flex;
      width: 100%;
      height: 100%;

      &.right {
        justify-content: flex-end;

        @include until($widthTablet) {
          justify-content: center;
        }
      }

      @include until($widthTablet) {
        &:not(.right) {
          display: none;
        }
      }
    }

    picture {
      position: relative;
      max-width: 472px;
      width: 100%;
      aspect-ratio: 1/1;
    }
  }

  .producers-section {
    padding-bottom: var(--spacing-900);

    @include until($widthDesktop) {
      overflow: visible;
    }

    @include until($widthTablet) {
      padding-bottom: var(--spacing-600);
    }

    .intro {
      max-width: 820px;
      margin-bottom: var(--spacing-600);
    }

    .scrollable-wrapper {
      @include until($widthDesktop) {
        width: 100%;
        overflow-x: auto;
        padding: 0 var(--spacing-700);
      }

      @include until($widthTablet) {
        padding: 0 var(--spacing-500);
      }
    }

    .producers {
      display: grid;
      gap: var(--spacing-500);
      grid-template-columns: repeat(4, minmax(0, 1fr));

      @include until($widthDesktop) {
        width: calc((4 * 290px) + (3 * #{var(--spacing-500)}));
        grid-template-columns: repeat(4, 290px);
      }
    }

    .cta {
      width: fit-content;
      display: block;
      margin: calc(var(--spacing-600) + var(--spacing-300)) auto 0;
    }
  }
}
